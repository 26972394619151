import React from "react";
import styles from "./list.module.css";
import { MapPin, Mail, Phone, Printer } from "react-feather";
import Loader from "../../../admin/components/Loader";
import { authData } from "../../../admin/components/getAuth";
import axios from "axios";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { ComponentToPrint } from "./ComponentToPrint";

export default function DetailsPopup({ bookingId }) {
  const auth = authData();
  const componentRef = React.useRef();
  const API_TOKEN = auth?.api_token;
  const [loading, setLoading] = React.useState(false);
  const [bookingData, setBookingData] = React.useState({});

  React.useEffect(() => {
    setLoading(true);
    axios(process.env.REACT_APP_API_URL + "v1/bookings/" + bookingId, {
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
      },
    })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          setLoading(false);
          let bookingDataTemp = response.data.data;
          setBookingData(bookingDataTemp);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [API_TOKEN, bookingId]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <React.Fragment>
      {loading && <Loader />}

      <div style={{ display: "none" }}>
        <ComponentToPrint ref={componentRef} bookingData={bookingData} />
      </div>

      <div className={`${styles.BodyHWithIcon}`}>
        <p>
          Please find the details of order #{bookingData?.reference_id} below:
        </p>
        <div className={`${styles.BodyHeadIcon}`}>
          <button onClick={handlePrint}>
            <Printer />
          </button>
        </div>
      </div>

      <div className={`${styles.MondalBodyDeRow}`}>
        <div className={`${styles.ModalDeBigSec}`}>
          <p className={`${styles.StepBoxTitle2}`}>Customer Account Details</p>
          <ul className={`${styles.SummeryListSec}`}>
            <li>
              <p className={`${styles.SummeryHeadTitle}`}>Customer ID:</p>
              <p className={`${styles.SummeryHeadText}`}>
                {bookingData?.customer_type === "Guest"
                  ? bookingData?.guest_customer?.customer_id
                  : bookingData?.customer?.customer_id}
              </p>
            </li>
            <li>
              <p className={`${styles.SummeryHeadTitle}`}>Customer Name:</p>
              <p className={`${styles.SummeryHeadText}`}>
                {bookingData?.customer_type === "Guest"
                  ? bookingData?.guest_customer?.name
                  : bookingData?.customer?.name}
              </p>
            </li>
            <li>
              <p className={`${styles.SummeryHeadTitle}`}>Customer Mobile:</p>
              <p className={`${styles.SummeryHeadText}`}>
                {bookingData?.customer_type === "Guest"
                  ? bookingData?.guest_customer?.phone
                  : bookingData?.customer?.phone}
              </p>
            </li>
            <li>
              <p className={`${styles.SummeryHeadTitle}`}>Customer Email:</p>
              <p className={`${styles.SummeryHeadText}`}>
                {bookingData?.customer_type === "Guest"
                  ? bookingData?.guest_customer?.email
                  : bookingData?.customer?.email}
              </p>
            </li>
          </ul>
        </div>
        <div className={`${styles.ModalDeBigSec}`}>
          <p className={`${styles.StepBoxTitle2}`}>Additional Info</p>
          <ul className={`${styles.SummeryListSec}`}>
            <li>
              <p className={`${styles.SummeryHeadTitle}`}>Booking Taken by:</p>
              <p className={`${styles.SummeryHeadText}`}>
                {bookingData?.created_user}
              </p>
            </li>
            <li>
              <p className={`${styles.SummeryHeadTitle}`}>
                Booking Last Edited by:
              </p>
              <p className={`${styles.SummeryHeadText}`}>
                {bookingData?.edited_user}
              </p>
            </li>
            <li>
              <p className={`${styles.SummeryHeadTitle}`}>
                Booking in Container:
              </p>
              <p className={`${styles.SummeryHeadText}`}>-</p>
            </li>
            <li>
              <p className={`${styles.SummeryHeadTitle}`}>Loaded:</p>
              <p className={`${styles.SummeryHeadText}`}>
                Loaded {bookingData?.loaded_items} Item(s) | Remaining{" "}
                {bookingData?.total_items - bookingData?.loaded_items} Item(s)
              </p>
            </li>
            <li>
              <p className={`${styles.SummeryHeadTitle}`}>Items:</p>
              <p className={`${styles.SummeryHeadText}`}>
                {bookingData?.total_items} Item(s)
              </p>
            </li>
            <li>
              <p className={`${styles.SummeryHeadTitle}`}>Booking Picked by:</p>
              <p className={`${styles.SummeryHeadText}`}>
                {bookingData?.picked_driver
                  ? bookingData?.picked_driver?.name
                  : "-"}
              </p>
            </li>
            <li>
              <p className={`${styles.SummeryHeadTitle}`}>Delivered by:</p>
              <p className={`${styles.SummeryHeadText}`}>
                {bookingData?.delivery_driver
                  ? bookingData?.delivery_driver?.name
                  : "-"}
              </p>
            </li>
            <li>
              <p className={`${styles.SummeryHeadTitle}`}>Customer Sgnature:</p>
              <p className={`${styles.SummeryHeadText}`}>
                {bookingData?.customer_signature ? (
                  <img
                    src={bookingData?.customer_signature}
                    alt=""
                    style={{ maxWidth: "150px", maxHeight: "40px" }}
                  />
                ) : (
                  "-"
                )}
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div className={`${styles.MondalBodyDeRow}`}>
        <div className={`${styles.ModalDeBigSec}`}>
          <p className={`${styles.StepBoxTitle2}`}>Pickup Photo(s)</p>
          <div className={`${styles.ImageRow}`}>
            {bookingData?.images?.map((img) => {
              return (
                <div className={`${styles.imgDiv}`}>
                  <img src={img.image_url} alt="" />
                </div>
              );
            })}
          </div>
        </div>
        <div className={`${styles.ModalDeBigSec}`}>
          <p className={`${styles.StepBoxTitle2}`}>Delivery Photo(s)</p>
          <div className={`${styles.ImageRow}`}>
            {bookingData?.delivery_images?.map((img) => {
              return (
                <div className={`${styles.imgDiv}`}>
                  <img src={img.image_url} alt="" />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {bookingData?.description && (
        <div className={`${styles.BookingNote}`}>
          <p className={`${styles.Title}`}>Booking Note:</p>
          <p className={`${styles.Text}`}>{bookingData?.description}</p>
        </div>
      )}

      {bookingData?.delivery_note && (
        <div className={`${styles.BookingNote}`}>
          <p className={`${styles.Title}`}>Delivery Note:</p>
          <p className={`${styles.Text}`}>{bookingData?.delivery_note}</p>
        </div>
      )}

      {(bookingData?.status === -1 || bookingData?.status === "-1") && (
        <div className={`${styles.BookingNote}`}>
          <p className={`${styles.Title}`}>Cancel Note:</p>
          <p className={`${styles.Text}`}>{bookingData?.cancel_note}</p>
        </div>
      )}

      <div className={`${styles.MondalBodyDeRow2}`}>
        <p className={`${styles.FullTitle}`}>Collection and delivery details</p>
        <div className={`${styles.ModalDeBigSec}`}>
          <p className={`${styles.StepBoxTitle2}`}>Pickup Address</p>
          <p className={`${styles.PickName}`}>
            {bookingData?.pickup_details?.name}
          </p>
          <p className={`${styles.DetailsList}`}>
            <MapPin />
            {bookingData?.pickup_details?.address}
          </p>
          <p className={`${styles.DetailsList}`}>
            <Mail />
            {bookingData?.pickup_details?.email}
          </p>
          <p className={`${styles.DetailsList}`}>
            <Phone />
            {bookingData?.pickup_details?.phone}
          </p>
          {bookingData?.pickup_details?.alt_phones?.map((item) => {
            return (
              <p className={`${styles.DetailsList}`}>
                <Phone />
                {item}
              </p>
            );
          })}
        </div>
        <div className={`${styles.ModalDeBigSec}`}>
          <p className={`${styles.StepBoxTitle2}`}>Delivery Address</p>
          <p className={`${styles.PickName}`}>
            {bookingData?.dropoff_details?.name}
          </p>
          <p className={`${styles.DetailsList}`}>
            <MapPin />
            {bookingData?.dropoff_details?.address}
          </p>
          <p className={`${styles.DetailsList}`}>
            <Mail />
            {bookingData?.dropoff_details?.email}
          </p>
          <p className={`${styles.DetailsList}`}>
            <Phone />
            {bookingData?.dropoff_details?.phone}
          </p>
          {bookingData?.dropoff_details?.alt_phones?.map((item) => {
            return (
              <p className={`${styles.DetailsList}`}>
                <Phone />
                {item}
              </p>
            );
          })}
        </div>
      </div>

      <div className={`${styles.MondalBodyDeRow2}`}>
        <p className={`${styles.FullTitle}`}>Driver Details</p>
        {bookingData?.picked_driver ? (
          <div className={`${styles.ModalDeBigSec}`}>
            <p className={`${styles.StepBoxTitle2}`}>Pickup Driver name</p>
            <p className={`${styles.PickName}`}>
              {bookingData?.picked_driver?.name}
            </p>
            <p className={`${styles.DetailsList}`}>
              <Mail />
              {bookingData?.picked_driver?.email}
            </p>
            <p className={`${styles.DetailsList}`}>
              <Phone />
              {bookingData?.picked_driver?.mobile}
            </p>
          </div>
        ) : (
          <div>
            <p
              className={`${styles.PickName}`}
              style={{ fontSize: "15px", fontWeight: 500 }}
            >
              Driver is not assigned yet.
            </p>
          </div>
        )}
        {bookingData?.delivery_driver ? (
          <div className={`${styles.ModalDeBigSec}`}>
            <p className={`${styles.StepBoxTitle2}`}>Delivery Driver name</p>
            <p className={`${styles.PickName}`}>
              {bookingData?.delivery_driver?.name}
            </p>
            <p className={`${styles.DetailsList}`}>
              <Mail />
              {bookingData?.delivery_driver?.email}
            </p>
            <p className={`${styles.DetailsList}`}>
              <Phone />
              {bookingData?.delivery_driver?.mobile}
            </p>
          </div>
        ) : (
          <div>
            <p
              className={`${styles.PickName}`}
              style={{ fontSize: "15px", fontWeight: 500 }}
            >
              Driver is not assigned yet.
            </p>
          </div>
        )}
      </div>

      <div className={`${styles.MondalBodyDeRow2}`}>
        <p className={`${styles.FullTitle}`}>Item details</p>
        <div className={`${styles.ModalDeFullSec}`}>
          <p className={`${styles.StepBoxTitle2}`}>
            Pickup Date:{" "}
            {moment(bookingData?.pickup_date).format("ddd MMM D, YYYY")}
          </p>
          {bookingData?.is_delivery === 1 && (
            <div className={`${styles.TableSec}`}>
              <table>
                <tr>
                  <td
                    className={`${styles.TableHead}`}
                    style={{ background: "#E64F22", color: "#ffffff" }}
                  >
                    Delivery ID
                  </td>
                  <td
                    className={`${styles.TableHead}`}
                    style={{ background: "#E64F22", color: "#ffffff" }}
                  >
                    Item
                  </td>
                  <td
                    className={`${styles.TableHead}`}
                    style={{ background: "#E64F22", color: "#ffffff" }}
                  >
                    Qty
                  </td>
                  <td
                    className={`${styles.TableHead}`}
                    style={{ background: "#E64F22", color: "#ffffff" }}
                  >
                    Cost
                  </td>
                </tr>
                {bookingData?.delivery_products?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className={`${styles.TableCon}`}>{item.item_id}</td>
                      <td className={`${styles.TableCon}`}>
                        {item.product_title_new}
                      </td>
                      <td className={`${styles.TableCon}`}>{item.quantity}</td>
                      <td className={`${styles.TableCon}`}>
                        £{parseFloat(item.sub_amount).toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          )}
          <div className={`${styles.TableSec}`}>
            <table>
              <tr>
                <td
                  className={`${styles.TableHead}`}
                  style={{ background: "#002e5b", color: "#ffffff" }}
                >
                  Pickup ID
                </td>
                <td
                  className={`${styles.TableHead}`}
                  style={{ background: "#002e5b", color: "#ffffff" }}
                >
                  Item
                </td>
                <td
                  className={`${styles.TableHead}`}
                  style={{ background: "#002e5b", color: "#ffffff" }}
                >
                  Cost
                </td>
                <td
                  className={`${styles.TableHead}`}
                  style={{ background: "#002e5b", color: "#ffffff" }}
                >
                  Status
                </td>
                <td
                  className={`${styles.TableHead}`}
                  style={{ background: "#002e5b", color: "#ffffff" }}
                >
                  Container
                </td>
              </tr>
              {bookingData?.products?.map((item, index) => {
                return (
                  <tr>
                    <td className={`${styles.TableCon}`}>{item.item_id}</td>
                    <td className={`${styles.TableCon}`}>
                      {item.product_title_new}
                    </td>
                    <td className={`${styles.TableCon}`}>
                      £{parseFloat(item.sub_amount).toFixed(2)}
                    </td>
                    <td className={`${styles.TableCon}`}>
                      {item?.status_text !== ""
                        ? item.status_text
                        : bookingData?.status_text}
                    </td>
                    <td className={`${styles.TableCon}`}>
                      {item?.container_name}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
          <div className={`${styles.EstiAmmo}`}>
            <ul>
              <li>
                <p className={`${styles.EstiTitle}`}>Item Subtotal:</p>
                <p className={`${styles.EstiInput}`}>
                  £
                  {parseFloat(
                    bookingData?.amount + bookingData?.discount
                  )?.toFixed(2)}
                </p>
              </li>
              {parseInt(bookingData?.discount) > 0 && (
                <li>
                  <p className={`${styles.EstiTitle}`}>Discount:</p>
                  <p className={`${styles.EstiInput}`}>
                    £{parseFloat(bookingData?.discount)?.toFixed(2)}
                  </p>
                </li>
              )}
              <li>
                <p className={`${styles.EstiTitle}`}>Total VAT:</p>
                <p className={`${styles.EstiInput}`}>£0.00</p>
              </li>
              <li>
                <p className={`${styles.EstiTitle}`}>Insurance Charges:</p>
                <p className={`${styles.EstiInput}`}>£0.00</p>
              </li>
              {bookingData?.is_delivery === 1 && (
                <li>
                  <p className={`${styles.EstiTitle}`}>Delivery Charges:</p>
                  <p className={`${styles.EstiInput}`}>
                    £{parseFloat(bookingData?.delivery_total)?.toFixed(2)}
                  </p>
                </li>
              )}
              <li>
                <p className={`${styles.EstiTitle}`}>Order Total:</p>
                <p className={`${styles.EstiInput}`}>
                  £{parseFloat(bookingData?.total)?.toFixed(2)}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {bookingData?.payments?.length > 0 && (
        <div className={`${styles.MondalBodyDeRow2}`}>
          <p className={`${styles.FullTitle}`}>Payment Details</p>
          <div className={`${styles.ModalDeFullSec}`}>
            <div className={`${styles.TableSec}`}>
              <table>
                <tr>
                  <td
                    className={`${styles.TableHead}`}
                    style={{ background: "#E64F22", color: "#ffffff" }}
                  >
                    Paid Amount
                  </td>
                  <td
                    className={`${styles.TableHead}`}
                    style={{ background: "#E64F22", color: "#ffffff" }}
                  >
                    Payment Notes
                  </td>
                  <td
                    className={`${styles.TableHead}`}
                    style={{ background: "#E64F22", color: "#ffffff" }}
                  >
                    Created By
                  </td>
                  <td
                    className={`${styles.TableHead}`}
                    style={{ background: "#E64F22", color: "#ffffff" }}
                  >
                    Created Date
                  </td>
                </tr>
                {bookingData?.payments?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className={`${styles.TableCon}`}>
                        £{parseFloat(item.amount).toFixed(2)}
                      </td>
                      <td className={`${styles.TableCon}`}>{item.note}</td>
                      <td className={`${styles.TableCon}`}>{item.name}</td>
                      <td className={`${styles.TableCon}`}>
                        {moment(item.created_at).format("DD/MM/YYYY LT")}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
