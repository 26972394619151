import React from "react";
import styles from "./list.module.css";
import { Link } from "react-router-dom";
import {
  TextField,
  Button,
  Tooltip,
  Breadcrumbs,
  Typography,
} from "@mui/material";
import DataTable from "react-data-table-component";
//import { X, ChevronDown, Plus, Edit, Trash2, Book } from "react-feather";
import { X, ChevronDown, Plus, Edit, Trash2 } from "react-feather";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import { TableLoader } from "../../components/LoaderC";
import Loader from "../../components/Loader";
import { authData } from "../../components/getAuth";
import CustomerAddForm from "./addForm";
import MembershipCustomerAddForm from "./membershipCustomerForm";
import Modal from "react-bootstrap/Modal";
import withAdminAuth from "../../components/withAdminAuth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
class CustomerList extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
    this.state = {
      allData: [],
      data: [],
      filterText: "",
      modalShow: false,
      modalShow2: false,
      loading: false,
      contentLoading: true,
      currentItem: 0,
      currentItemStatus: 0,
      addModalShow: false,
      editModalShow: false,
      addMembershipModalShow: false,
      renewMembershipModalShow: false,
    };

    this.audience = process.env.REACT_APP_API_URL;
    this.app_folder_path = process.env.REACT_APP_FOLDER_PATH;
    this.authData = authData();

    this.columns = [
      {
        name: "Customer Id",
        selector: (row) => row.customer_id,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "Customer Name",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "Address",
        selector: (row) => row.address,
        sortable: true,
      },
      {
        name: "Phone",
        selector: (row) => row.phone,
        sortable: true,
      },
      {
        name: "Current membership",

        selector: (row) =>
          row.current_membership ? row.current_membership : "-",

        // sortable: true,
      },
      {
        name: "Membership expired",

        selector: (row) =>
          row.membership_expired_date
            ? moment(row.membership_expired_date).format("DD/MM/YYYY")
            : "-",
        // sortable: true,
      },
      {
        name: "Status",
        cell: (tableProps) =>
          tableProps.status === 1 || tableProps.status === "1" ? (
            <Dropdown className="StatusDropDown">
              <Dropdown.Toggle
                className={`${styles.ActiveBU}`}
                id={"dropdown-basic" + tableProps.id}
                size="sm"
              >
                Active <ChevronDown />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={this.statusChange.bind(this, tableProps.id, 0)}
                >
                  Archived
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Dropdown className="StatusDropDown">
              <Dropdown.Toggle
                className={`${styles.ArchiveBU}`}
                id={"dropdown-basic" + tableProps.id}
                size="sm"
              >
                Archived <ChevronDown />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={this.statusChange.bind(this, tableProps.id, 1)}
                >
                  Active
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ),
        sortable: false,
      },
      {
        name: "Action",
        cell: (tableProps) => (
          <div className={`${styles.ActionDiv}`}>
            <Tooltip title="Edit">
              <Button onClick={this.openEditForm.bind(this, tableProps.id)}>
                <Edit />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button onClick={this.modalOpen.bind(this, tableProps.id)}>
                <Trash2 />
              </Button>
            </Tooltip>

            {/*<Tooltip title="Add Membership">
              <Button
                onClick={this.addMembershipModalOpen.bind(this, tableProps.id)}
              >
                <Plus />
              </Button>
            </Tooltip>*/}
            {/* <Tooltip title="Renew Membership">
              <Button
                onClick={this.renewMembershipModalOpen.bind(
                  this,
                  tableProps.id
                )}
              >
                <Book />
              </Button>
            </Tooltip> */}
          </div>
        ),
        sortable: false,
      },
    ];
    this.customDataTableStyles = {
      head: {
        style: {
          zIndex: 0,
        },
      },
      headCells: {
        style: {
          backgroundColor: "#1e1e2d",
          color: "#fff",
        },
      },
    };
  }

  async componentDidMount() {
    try {
      axios(process.env.REACT_APP_API_URL + "v1/customers", {
        headers: {
          Authorization: `Bearer ${this.authData?.api_token}`,
        },
      })
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            let allData = response.data.data;
            this.setState({
              ...this.state,
              allData: allData,
              data: allData,
              contentLoading: false,
            });
          } else {
            this.setState({ ...this.state, contentLoading: false });
          }
        })
        .catch((error) => {
          this.setState({ ...this.state, contentLoading: false });
        });
    } catch (error) {
      this.setState({ ...this.state, contentLoading: false });
    }
  }
  onFilter(e) {
    this.setState(
      { ...this.state, filterText: e.target.value },
      this.filterHandler
    );
  }
  clearFilter() {
    this.setState({ ...this.state, filterText: "" }, this.filterHandler);
  }
  filterHandler() {
    let filteredData = this.state.allData;

    if (this.state.filterText !== "") {
      let inputVal = this.state.filterText;
      inputVal = inputVal.toLowerCase();
      filteredData = filteredData.filter((item) => {
        let name = item.first_name + " " + item.last_name;
        return (
          item.customer_id.toLowerCase().includes(inputVal) ||
          name.toLowerCase().includes(inputVal) ||
          item.email.toLowerCase().includes(inputVal) ||
          item.phone.toLowerCase().includes(inputVal) ||
          item.address.toLowerCase().includes(inputVal)
        );
      });
    }

    this.setState({ ...this.state, data: filteredData });
  }

  statusChange(id, status) {
    this.setState({ ...this.state, loading: true });
    axios
      .put(
        process.env.REACT_APP_API_URL + "v1/customers/" + id,
        { status: status },
        {
          headers: {
            Authorization: `Bearer ${this.authData?.api_token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          let allData = this.state.allData;
          let data = this.state.data;
          let allDataTemp = allData.map((i) => {
            if (i.id === id) return { ...i, status: status };
            return i;
          });
          let dataTemp = data.map((i) => {
            if (i.id === id) return { ...i, status: status };
            return i;
          });
          this.setState({
            ...this.state,
            allData: allDataTemp,
            data: dataTemp,
            loading: false,
          });
        } else {
          this.setState({ ...this.state, loading: false });
        }
      })
      .catch((error) => {
        this.setState({ ...this.state, loading: false });
      });
  }

  modalOpen(id) {
    this.setState({ ...this.state, modalShow: true, currentItem: id });
  }

  modalOpen2(id, status) {
    this.setState({
      ...this.state,
      modalShow2: true,
      currentItem: id,
      currentItemStatus: status,
    });
  }

  modalClose() {
    this.setState({
      ...this.state,
      modalShow: false,
      modalShow2: false,
      currentItem: 0,
      currentItemStatus: 0,
      addModalShow: false,
      editModalShow: false,
      addMembershipModalShow: false,
      renewMembershipModalShow: false,
    });
  }

  deleteConfirm() {
    let currentItem = this.state.currentItem;
    this.setState({
      ...this.state,
      modalShow: false,
      currentItem: 0,
      loading: true,
    });
    axios
      .delete(process.env.REACT_APP_API_URL + "v1/customers/" + currentItem, {
        headers: {
          Authorization: `Bearer ${this.authData?.api_token}`,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          let allData = this.state.allData;
          let data = this.state.data;
          let allDataTemp = allData.filter((i) => i.id !== currentItem);
          let dataTemp = data.filter((i) => i.id !== currentItem);
          this.setState({
            ...this.state,
            allData: allDataTemp,
            data: dataTemp,
            loading: false,
          });
        } else {
          this.setState({ ...this.state, loading: false });
        }
      })
      .catch((error) => {
        this.setState({ ...this.state, loading: false });
      });
  }

  openAddForm() {
    this.setState({ ...this.state, addModalShow: true });
  }

  openEditForm(id) {
    this.setState({ ...this.state, editModalShow: true, currentItem: id });
  }
  addMembershipModalOpen(id) {
    this.setState({
      ...this.state,
      addMembershipModalShow: true,
      currentItem: id,
    });
  }
  // renewMembershipModalOpen(id) {
  //   this.setState({
  //     ...this.state,
  //     renewMembershipModalShow: true,
  //     currentItem: id,
  //   });
  // }

  onSubmitAddForm() {
    axios(process.env.REACT_APP_API_URL + "v1/customers", {
      headers: {
        Authorization: `Bearer ${this.authData?.api_token}`,
      },
    }).then((response) => {
      if (response.data.status === "SUCCESS") {
        let allData = response.data.data;
        this.setState(
          {
            ...this.state,
            allData: allData,
            data: allData,
            addModalShow: false,
            editModalShow: false,
            addMembershipModalShow: false,
          },
          this.filterHandler
        );
      } else {
        this.setState({
          ...this.state,
          addModalShow: false,
          editModalShow: false,
          addMembershipModalShow: false,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.loading && <Loader />}
        {this.state.contentLoading && (
          <div className="LoaderCard">
            <div className="Loader TableLoader">
              <TableLoader />
            </div>
          </div>
        )}
        {!this.state.contentLoading && (
          <div className={`${styles.MainDiv}`}>
            <div className={`${styles.PageTitleWrap}`}>
              <p className={`${styles.PageTitle}`}>Customer List</p>
              <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
                <Typography color="text.primary">Customer</Typography>
                <Typography color="text.primary">List</Typography>
              </Breadcrumbs>
            </div>
            <div className={`${styles.MainCard}`}>
              <div className="TableFilterHead">
                <div className="DataTableSearch">
                  <TextField
                    id="search"
                    type="text"
                    placeholder="Search"
                    aria-label="Search Input"
                    value={this.state.filterText}
                    onChange={this.onFilter.bind(this)}
                  />
                  {this.state.filterText && (
                    <Button
                      type="button"
                      className="CloseBU"
                      onClick={this.clearFilter.bind(this)}
                    >
                      <X />
                    </Button>
                  )}
                </div>
                <div className="TableFilterBUSec">
                  <Link
                    className="TableAddBU"
                    onClick={this.openAddForm.bind(this)}
                  >
                    <Plus /> Customer
                  </Link>
                </div>
              </div>

              <div className="TableContent">
                <DataTable
                  className="DataTable"
                  columns={this.columns}
                  data={this.state.data}
                  pagination
                  customStyles={this.customDataTableStyles}
                />
              </div>
            </div>
          </div>
        )}

        <Modal
          show={this.state.modalShow2}
          onHide={this.modalClose.bind(this)}
          centered
          className="CusModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.currentItemStatus === 1 ? "Archive" : "Active"} Item
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="Message">
              Are you sure you want to{" "}
              {this.state.currentItemStatus === 1 ? "archive" : "active"} this
              item?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="PopUpCanBU" onClick={this.modalClose.bind(this)}>
              Cancel
            </Button>
            <Button
              className="PopUpSubmitBU"
              onClick={this.statusChange.bind(this)}
            >
              {this.state.currentItemStatus === 1 ? "Archive" : "Active"}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalShow}
          onHide={this.modalClose.bind(this)}
          centered
          className="CusModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="Message">
              Are you sure you want to delete this item?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="PopUpCanBU" onClick={this.modalClose.bind(this)}>
              Cancel
            </Button>
            <Button
              className="PopUpSubmitBU"
              onClick={this.deleteConfirm.bind(this)}
            >
              Confirm Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.addModalShow}
          onHide={this.modalClose.bind(this)}
          centered
          className="CusModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomerAddForm
              onSubmit={this.onSubmitAddForm.bind(this)}
              isAddMode={true}
              id={this.state.currentItem}
              modalClose={this.modalClose.bind(this)}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.editModalShow}
          onHide={this.modalClose.bind(this)}
          centered
          className="CusModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomerAddForm
              onSubmit={this.onSubmitAddForm.bind(this)}
              isAddMode={false}
              id={this.state.currentItem}
              modalClose={this.modalClose.bind(this)}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.addMembershipModalShow}
          onHide={this.modalClose.bind(this)}
          centered
          className="CusModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Membership</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MembershipCustomerAddForm
              onSubmit={this.onSubmitAddForm.bind(this)}
              isAddMode={true}
              id={this.state.currentItem}
              modalClose={this.modalClose.bind(this)}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.renewMembershipModalShow}
          onHide={this.modalClose.bind(this)}
          centered
          className="CusModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Renew Membership</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MembershipCustomerAddForm
              onSubmit={this.onSubmitAddForm.bind(this)}
              isAddMode={false}
              id={this.state.currentItem}
              modalClose={this.modalClose.bind(this)}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withAdminAuth(CustomerList);
