import * as React from "react";
import styles from "./add.module.css";
import { useForm } from "react-hook-form";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import moment from "moment";
import { FormControlLabel, Checkbox } from "@mui/material";
import Loader from "../../../admin/components/Loader";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { authData } from "../../../admin/components/getAuth";

function Step4({ bookingData, backToTab }) {
  const auth = authData();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [SubTotal, setSubTotal] = React.useState(0);
  const [Total, setTotal] = React.useState(0);
  const VATCharge = 0;
  const InsuranceCharge = 0;
  const [discountChagre, setDiscountCharge] = React.useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  React.useState(() => {
    console.log(bookingData);
    let subtotalTemp = 0;
    bookingData?.products?.forEach((item) => {
      subtotalTemp += parseFloat(item.total);
    });
    let discount = (subtotalTemp * bookingData?.discount) / 100;
    setDiscountCharge(discount);
    if (bookingData?.is_delivery) {
      bookingData?.delivery_products?.forEach((item) => {
        subtotalTemp += parseFloat(item.total);
      });
    }

    setSubTotal(subtotalTemp);
    setTotal(subtotalTemp + VATCharge + InsuranceCharge - discount);
  }, [bookingData, discountChagre]);

  const onSubmit = (data) => {
    setLoading(true);

    let noOfItems = 0;
    let subTotal = 0;
    let delivery_total = 0;

    let delivery_products = [];
    if (bookingData?.is_delivery) {
      delivery_products = bookingData.delivery_products.map((item) => {
        delivery_total += parseFloat(item.total);
        if (item?.category_id?.value === 999) {
          return {
            product_id: 0,
            product_title: item?.item,
            price: parseInt(item.total) / parseInt(item.quantity),
            quantity: item.quantity,
            sub_amount: item.total,
          };
        } else {
          return {
            product_id: item?.item?.value,
            product_title: item?.item?.label,
            price: item?.item?.amount,
            quantity: item.quantity,
            sub_amount: item.total,
          };
        }
      });
    }
    let products = bookingData.products.map((item) => {
      noOfItems += 1;
      subTotal += parseFloat(item.total);
      if (item?.category_id?.value === 999) {
        return {
          product_id: 0,
          product_title: item?.item,
          price: item.total,
          quantity: 1,
          sub_amount: item.total,
          is_fragile: item?.is_fragile ? 1 : 0,
        };
      } else {
        return {
          product_id: item?.item?.value,
          product_title: item?.item?.label,
          price: item?.item?.amount,
          quantity: 1,
          sub_amount: item.total,
          is_fragile: item?.is_fragile ? 1 : 0,
        };
      }
    });

    let postData = {
      key_type: "email",
      key_value: auth?.customer?.email,
      customer_id: auth?.customer?.id,
      description: bookingData?.notes,
      no_of_items: noOfItems,
      is_delivery: bookingData?.is_delivery ? 1 : 0,
      insurance: bookingData?.insurance?.value,
      company_shipping: bookingData?.company_shipping?.value,
      company_name: bookingData?.company_name,
      registered_company: bookingData?.registered_company?.value,
      pickup_date: bookingData?.pickup_date,
      total: subTotal + delivery_total - discountChagre,
      vat: 0,
      amount: subTotal - discountChagre,
      delivery_total: delivery_total,
      shipping: 0,
      post_code: bookingData?.pickUpData?.post_code,
      delivery_products: delivery_products,
      products: products,
      pickUpData: bookingData?.pickUpData,
      dropOffData: bookingData?.dropOffData,
      discount: discountChagre,
    };

    axios
      .post(process.env.REACT_APP_API_URL + "v1/bookings", postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status === "SUCCESS") {
          navigate("/customer/my-bookings");
        }
      })
      .catch((eroor) => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loader />}
      <div className={`${styles.CustomWidth}`}>
        <div className={`${styles.SummerySec}`}>
          <div className={`${styles.SummerySecUnder}`}>
            <div className={`${styles.SummerySecRow}`}>
              <div className={`${styles.SummerySecFull}`}>
                <p>Customer Name:</p>
                <p>{bookingData?.user_id?.label}</p>
              </div>

              <div className={`${styles.SummerySecHalf}`}>
                <p className={`${styles.StepBoxTitle2}`}>
                  CONFIRM YOUR USER DETAILS
                </p>
                <ul className={`${styles.SummeryListSec}`}>
                  <li>
                    <p className={`${styles.SummeryHeadTitle}`}>Name:</p>
                    <p className={`${styles.SummeryHeadText}`}>
                      {bookingData?.pickUpData?.name}
                    </p>
                  </li>
                  <li>
                    <p className={`${styles.SummeryHeadTitle}`}>Address:</p>
                    <p className={`${styles.SummeryHeadText}`}>
                      {bookingData?.pickUpData?.address}
                    </p>
                  </li>
                  <li>
                    <p className={`${styles.SummeryHeadTitle}`}>Post Code:</p>
                    <p className={`${styles.SummeryHeadText}`}>
                      {bookingData?.pickUpData?.post_code}
                    </p>
                  </li>
                  <li>
                    <p className={`${styles.SummeryHeadTitle}`}>Contact No:</p>
                    <p className={`${styles.SummeryHeadText}`}>
                      {bookingData?.pickUpData?.phone &&
                        formatPhoneNumberIntl(bookingData?.pickUpData?.phone)}
                    </p>
                  </li>
                  {bookingData?.pickUpData?.alt_contact_nos?.map(
                    (item, index) => {
                      return (
                        <li>
                          <p className={`${styles.SummeryHeadTitle}`}>
                            Alt Contact No:
                          </p>
                          <p className={`${styles.SummeryHeadText}`}>
                            {item?.value && formatPhoneNumberIntl(item?.value)}
                          </p>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>

              <div className={`${styles.SummerySecHalf}`}>
                <p className={`${styles.StepBoxTitle2} ${styles.Heading}`}>
                  CONFIRM YOUR RECIPIENT DETAILS
                </p>
                <ul className={`${styles.SummeryListSec}`}>
                  <li>
                    <p className={`${styles.SummeryHeadTitle}`}>Name:</p>
                    <p className={`${styles.SummeryHeadText}`}>
                      {bookingData?.dropOffData?.name}
                    </p>
                  </li>
                  <li>
                    <p className={`${styles.SummeryHeadTitle}`}>Address:</p>
                    <p className={`${styles.SummeryHeadText}`}>
                      {bookingData?.dropOffData?.address}
                    </p>
                  </li>
                  <li>
                    <p className={`${styles.SummeryHeadTitle}`}>Contact No:</p>
                    <p className={`${styles.SummeryHeadText}`}>
                      {bookingData?.dropOffData?.phone &&
                        formatPhoneNumberIntl(bookingData?.dropOffData?.phone)}
                    </p>
                  </li>
                  {bookingData?.dropOffData?.alt_contact_nos?.map(
                    (item, index) => {
                      return (
                        <li>
                          <p className={`${styles.SummeryHeadTitle}`}>
                            Alt Contact No:
                          </p>
                          <p className={`${styles.SummeryHeadText}`}>
                            {item?.value && formatPhoneNumberIntl(item?.value)}
                          </p>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <p className={`${styles.StepBoxTitle}`}>CONFIRM YOUR ITEM DETAILS</p>

        <div className={`${styles.TableSec}`}>
          <table>
            {bookingData?.is_delivery && (
              <>
                <tr>
                  <td className={`${styles.TableHead}`}>Delivery Category</td>
                  <td className={`${styles.TableHead}`}>Delivery Item</td>
                  <td className={`${styles.TableHead}`}>Quantity</td>
                  <td className={`${styles.TableHead}`}>Total</td>
                </tr>
                {bookingData?.is_delivery &&
                  bookingData?.delivery_products?.map((item, index) => {
                    return (
                      <tr>
                        <td className={`${styles.TableCon}`}>
                          {item?.category_id?.label}
                        </td>
                        <td className={`${styles.TableCon}`}>
                          {item?.category_id?.value &&
                          item?.category_id?.value === 999
                            ? item?.item_id
                            : item?.item_id?.label}
                        </td>
                        <td className={`${styles.TableCon}`}>
                          {item?.quantity}
                        </td>
                        <td className={`${styles.TableCon}`}>
                          &pound; {parseFloat(item.total).toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
              </>
            )}
            <tr>
              <td className={`${styles.TableHead}`}>Pickup Item</td>
              <td className={`${styles.TableHead}`}>Fragile</td>
              <td className={`${styles.TableHead}`}>Estimated Value</td>
            </tr>
            {bookingData?.products?.map((item, index) => {
              return (
                <tr>
                  <td className={`${styles.TableCon}`}>
                    {item?.category_id?.label}
                    {item?.category_id?.value &&
                    item?.category_id?.value === 999
                      ? " - " + item?.item
                      : item?.category_id?.no_of_child > 0
                      ? " - " + item?.item?.label
                      : ""}
                  </td>
                  <td className={`${styles.TableCon}`}>
                    {item?.is_fragile ? "Yes" : "No"}
                  </td>
                  <td className={`${styles.TableCon}`}>
                    &pound; {parseFloat(item.total).toFixed(2)}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan="2" className={`${styles.TableCon}`}>
                <p className={`${styles.SummeryPPTI}`}>Order Subtotal:</p>
                <p className={`${styles.SummeryPPTI}`}>Discount:</p>
                <p className={`${styles.SummeryPPTI}`}>Total VAT:</p>
                <p className={`${styles.SummeryPPTI}`}>Insurance Charges:</p>
                <p className={`${styles.SummeryPPTI} ${styles.Big}`}>Total:</p>
              </td>
              <td className={`${styles.TableCon}`}>
                <p className={`${styles.SummeryPPTE}`}>
                  £{SubTotal.toFixed(2)}
                </p>
                <p className={`${styles.SummeryPPTE}`}>
                  £{discountChagre.toFixed(2)}
                </p>

                <p className={`${styles.SummeryPPTE}`}>
                  £{VATCharge.toFixed(2)}
                </p>
                <p className={`${styles.SummeryPPTE}`}>
                  £{InsuranceCharge.toFixed(2)}
                </p>
                <p className={`${styles.SummeryPPTE} ${styles.Big}`}>
                  £{Total.toFixed(2)}
                </p>
              </td>
            </tr>
          </table>
        </div>

        <div className={`${styles.TableConSec}`}>
          <p className={`${styles.TableConSecTitle}`}>To be collected:</p>
          <p className={`${styles.TableConSecText}`}>
            {moment(bookingData.pickup_date).format("ddd MMM D, YYYY")}
          </p>
        </div>

        <div className={`${styles.TableConSec}`}>
          <p className={`${styles.TableConSecTitle}`}>Booking Notes:</p>
          <p className={`${styles.TableConSecText}`}>{bookingData?.notes}</p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControlLabel
            control={
              <Checkbox {...register("is_accept", { required: true })} />
            }
            label="I confirm that the order summary above is correct *"
            className={`${styles.CheckBox} CheckBox`}
          />
          {errors?.is_accept && (
            <p className={`${styles.ErrorM}`}>
              Please accept terms & condition
            </p>
          )}

          <div className={`${styles.StepBUSec}`}>
            <button
              type="button"
              onClick={backToTab}
              className={`${styles.Previous}`}
            >
              Back
            </button>
            <button type="submit" className={`${styles.Next}`}>
              Confirm Booking
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Step4;
