import React, { useState, useEffect } from "react";
import styles from "./add.module.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { FromLoader } from "../../components/LoaderC";
import Loader from "../../components/Loader";
import { authData } from "../../components/getAuth";
import Select from "react-select";
import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const durationList = [
  { value: "1 month", label: "1 month" },
  { value: "3 months", label: "3 months" },
  { value: "6 months", label: "6 months" },
  { value: "12 months", label: "12 months" },
];
function MembershipAddForm({ onSubmit, isAddMode, id, modalClose }) {
  const auth = authData();
  const API_TOKEN = auth?.api_token;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);

  let schema;
  schema = yup.object().shape({
    name: yup
      .string()
      .required("This field is Required")
      .max(50, "Name cannot be more than 50 characters"),
    price: yup.string().required("This field is Required"),
    duration: yup.object().required("This field is Required"),
    discount: yup.string().required("This field is Required"),
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    if (!isAddMode) {
      if (contentLoading) {
        axios
          .get(process.env.REACT_APP_API_URL + "v1/memberships/" + id, {
            headers: {
              Authorization: `Bearer ${API_TOKEN}`,
            },
          })
          .then((response) => {
            setContentLoading(false);
            if (response.data.status === "SUCCESS") {
              let itemData = response.data.data;
              setValue("name", itemData.name);
              setValue("price", itemData.price);
              setValue("discount", itemData.discount);
              let catItem = durationList.filter(
                (i) => i.value === itemData.duration
              );

              if (catItem.length) {
                setValue("duration", catItem[0]);
              }
            }
          })
          .catch((error) => {
            setContentLoading(false);
          });
      }
    } else {
      setContentLoading(false);
    }
  }, [API_TOKEN, id, isAddMode, setValue, contentLoading]);

  async function onSubmitLocal(data) {
    if (data.duration) {
      data.duration = data.duration.value;
    }
    const postData = data;

    setLoading(true);

    if (isAddMode) {
      return axios
        .post(process.env.REACT_APP_API_URL + "v1/memberships", postData, {
          headers: {
            Authorization: `Bearer ${API_TOKEN}`,
          },
        })
        .then((response) => {
          setLoading(false);
          if (response.data.status === "SUCCESS") {
            toast.success(response.data.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
            onSubmit();
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      return axios
        .post(
          process.env.REACT_APP_API_URL + "v1/memberships/" + id,
          postData,
          {
            headers: {
              Authorization: `Bearer ${API_TOKEN}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          if (response.data.status === "SUCCESS") {
            toast.success(response.data.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
            onSubmit();
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      {contentLoading && (
        <div className="LoaderCard">
          <div className="Loader FromLoader">
            <FromLoader />
          </div>
        </div>
      )}
      {!contentLoading && (
        <div className={`${styles.AddUserMain}`}>
          <form onSubmit={handleSubmit(onSubmitLocal)}>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Name*</label>
                <input
                  {...register("name")}
                  className={`${styles.FormControl}`}
                />
                {errors.name && (
                  <span className={`${styles.ErrorM}`}>
                    {errors.name.message}
                  </span>
                )}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Duration*</label>
                <Controller
                  control={control}
                  options={durationList}
                  {...register("duration")}
                  render={({ field }) => (
                    <Select {...field} options={durationList} />
                  )}
                />
                {errors.duration && !watch().duration && (
                  <span className={`${styles.ErrorM}`}>
                    {errors.duration.message}
                  </span>
                )}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>
                  Price (&pound;)*
                </label>
                <Controller
                  autoComplete="off"
                  control={control}
                  name="price"
                  className={`${styles.FormControl}`}
                  render={({ field: { onChange, name, value } }) => (
                    <NumericFormat
                      decimalScale={2}
                      name={name}
                      value={value}
                      onChange={onChange}
                      className={`${styles.FormControl}`}
                    />
                  )}
                />
                {errors.price && (
                  <span className={`${styles.ErrorM}`}>
                    {errors.price.message}
                  </span>
                )}
              </div>
            </div>

            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>
                  Discount (&pound;)*
                </label>
                <Controller
                  autoComplete="off"
                  control={control}
                  name="discount"
                  className={`${styles.FormControl}`}
                  render={({ field: { onChange, name, value } }) => (
                    <NumericFormat
                      decimalScale={2}
                      name={name}
                      value={value}
                      onChange={onChange}
                      className={`${styles.FormControl}`}
                    />
                  )}
                />
                {errors.discount && (
                  <span className={`${styles.ErrorM}`}>
                    {errors.discount.message}
                  </span>
                )}
              </div>
            </div>

            <div className={`${styles.FormButtonArea}`}>
              <button onClick={modalClose} className={`${styles.CancelBU}`}>
                Cancel
              </button>
              <input
                className={`${styles.SubmitBU}`}
                value="Submit"
                type="submit"
              />
            </div>
          </form>
        </div>
      )}
    </React.Fragment>
  );
}

export default MembershipAddForm;
