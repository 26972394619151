import React, { useState, useEffect } from "react";
import styles from "./add.module.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { FromLoader } from "../../components/LoaderC";
import Loader from "../../components/Loader";
import { authData } from "../../components/getAuth";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

function MembershipCustomerAddForm({ onSubmit, isAddMode, id, modalClose }) {
  const auth = authData();
  const API_TOKEN = auth?.api_token;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [membershipList, setMembershipList] = useState([]);
  const [selectmembership, setSelectmembership] = useState([]);
  const [expireddate, setExpireddate] = useState("");

  let schema;
  schema = yup.object().shape({
    membership_id: yup.object().required("This field is Required"),
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "v1/membership-list", {
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
        },
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          let itemData = response.data.data;
          // itemData = [{ value: 0, label: "No Category" }].concat(itemData);
          setMembershipList(itemData);
          setContentLoading(false);
        }
      });
  }, [API_TOKEN]);

  async function onSubmitLocal(data) {
    if (data.membership_id) {
      data.membership_id = data.membership_id.value;
    }
    if (id) {
      data.customer_id = id;
    }
    if (selectmembership?.duration) {
      data.duration = selectmembership?.duration;
    }
    const postData = data;
    console.log("postData");
    console.log(postData);
    setLoading(true);
    if (isAddMode) {
      return axios
        .post(process.env.REACT_APP_API_URL + "v1/add-membership", postData, {
          headers: {
            Authorization: `Bearer ${API_TOKEN}`,
          },
        })
        .then((response) => {
          setLoading(false);
          if (response.data.status === "SUCCESS") {
            toast.success(response.data.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
            onSubmit();
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
    //else {
    //   return axios
    //     .post(
    //       process.env.REACT_APP_API_URL + "v1/memberships/" + id,
    //       postData,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${API_TOKEN}`,
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       setLoading(false);
    //       if (response.data.status === "SUCCESS") {
    //         toast.success(response.data.message, {
    //           position: "top-center",
    //           autoClose: 3000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: false,
    //           progress: undefined,
    //           theme: "light",
    //         });
    //         onSubmit();
    //       }
    //     })
    //     .catch((error) => {
    //       setLoading(false);
    //     });
    // }
  }
  const getMember = (event) => {
    let selItem = membershipList.filter((i) => i.value === event.value);
    if (selItem.length) {
      setSelectmembership(selItem[0]);
      let expdate = selItem[0].duration.split(" ");
      console.log(expdate);
      let cur_date = moment().format("DD/MM/YYYY");
      let cur_date1 = moment().add(expdate[0], expdate[1]);
      console.log(cur_date);
      console.log(cur_date1.format("DD/MM/YYYY"));
      setExpireddate(cur_date1.format("DD/MM/YYYY"));
    }
  };

  return (
    <React.Fragment>
      {loading && <Loader />}
      {contentLoading && (
        <div className="LoaderCard">
          <div className="Loader FromLoader">
            <FromLoader />
          </div>
        </div>
      )}
      {!contentLoading && (
        <div className={`${styles.AddUserMain}`}>
          <form onSubmit={handleSubmit(onSubmitLocal)}>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Membership*</label>
                <Controller
                  control={control}
                  options={membershipList}
                  {...register("membership_id")}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={membershipList}
                      onChange={(e) => {
                        field.onChange(e);
                        getMember(e);
                      }}
                    />
                  )}
                />
                {errors.membership_id && !watch().membership_id && (
                  <span className={`${styles.ErrorM}`}>
                    {errors.membership_id.message}
                  </span>
                )}
              </div>

              <div className={`${styles.FormGroupFull} new`}>
                {selectmembership?.duration && (
                  <div>
                    <label className={`${styles.FormLabel}`}>
                      Duration : {selectmembership?.duration}
                    </label>
                  </div>
                )}
                {selectmembership?.price && (
                  <div>
                    <label className={`${styles.FormLabel}`}>
                      Price : {selectmembership?.price}
                    </label>
                  </div>
                )}
                {selectmembership?.discount && (
                  <div>
                    <label className={`${styles.FormLabel}`}>
                      Discount : {selectmembership?.discount}
                    </label>
                  </div>
                )}

                {selectmembership?.duration && (
                  <div>
                    <label className={`${styles.FormLabel}`}>
                      Expired : {expireddate}
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div className={`${styles.FormButtonArea}`}>
              <button onClick={modalClose} className={`${styles.CancelBU}`}>
                Cancel
              </button>
              <input
                className={`${styles.SubmitBU}`}
                value="Submit"
                type="submit"
              />
            </div>
          </form>
        </div>
      )}
    </React.Fragment>
  );
}

export default MembershipCustomerAddForm;
