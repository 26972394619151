import React, { useEffect } from 'react';
import styles from './list.module.css';
import { Breadcrumbs, Button, TextField, Typography  } from '@mui/material';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { TableLoader } from  '../../components/LoaderC';
import Loader from  '../../components/Loader';
import {authData} from "../../components/getAuth";
import moment from 'moment';
import withAdminAuth from "../../components/withAdminAuth";
import { X } from 'react-feather';
import { DateRangePicker } from 'rsuite';

const PickedUpItems = () => {
  const auth = authData();
  const [loading, setLoading] = React.useState(false);
  const [contentLoading, setContentLoading] = React.useState(false);
  const [allData, setAllData] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [filterText, setFilterText] = React.useState('');
  const [filterDate, setFilterDate] = React.useState(null);
  
  const columns = [{
    name: 'Booking Id',
    selector: row =>  row?.reference_id,
    sortable: true
  },{
    name: 'Customer Name',
    selector: row => row?.customer_name,
    sortable: true
  },{
    name: 'Pickup Address',
    selector: row => row?.pickup_details?.address,
    sortable: true
  },{
    name: 'Pickup Date',
    selector: row => moment(row?.pickup_date).format('DD/MM/YYYY'),
    sortable: true
  },{
    name: '',
    cell: tableProps => (<Button className={`${styles.StatusBU}`} onClick={makeReceivedAll.bind(this,tableProps)}>Receive All</Button>)
  }];
  
  const subColumns = [{
    name: 'Item Id',
    selector: row => row?.item_id,
    sortable: false
  },{
    name: 'Item',
    selector: row => row?.product_title_new,
    sortable: false
  },{
    name: '',
    cell: tableProps => ((tableProps?.is_wh_received === 0) ? <Button className={`${styles.StatusBU}`} onClick={makeReceived.bind(this,tableProps)}>Receive</Button>:null)
  }];
  
  const customDataTableStyles = {
    headRow: {
      style: {
          backgroundColor: '#1e1e2d',
          color: '#fff'
      },
    },
    head: {
      style: {
        zIndex:0
      },
    },
    headCells: {
      style: {
          backgroundColor: '#1e1e2d',
          color: '#fff'
      },
    },
  }
  
  const customDataTableStyles2 = {
    headCells: {
      style: {
        backgroundColor: '#F9DAD0',
        color: '#000'
      },
    },
    rows: {
      style: {
        backgroundColor: '#FFF',
      },
    },
  }
  
  const ExpandedComponent = ({ data }) => <div className={`${styles.ExpandedDiv}`}>
    <div style={{margin:'0 5px 20px'}}>
      <DataTable className='DataTable' columns={subColumns} data={data?.products} customStyles={customDataTableStyles2}  />
    </div>
  </div>;

  useEffect(()=>{
    try {
      setContentLoading(true);
      axios(process.env.REACT_APP_API_URL + 'v1/picked-item', {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setContentLoading(false);
        if(response.data.status === 'SUCCESS'){
          let allData = response.data.data;
          setAllData(allData);
        }
      });
    } catch (error) {
      setContentLoading(false);
    }
  },[auth?.api_token]);

  useEffect(()=>{
    let allDataTemp = allData;

    if (filterText !== '') {
      let inputVal = filterText;
      inputVal = inputVal.toLowerCase();

      allDataTemp = allDataTemp.filter((item) => {
        return item?.reference_id.toLowerCase().includes(inputVal) || item?.customer_name.toLowerCase().includes(inputVal) || item?.pickup_details?.address.toLowerCase().includes(inputVal);
      });
    }

    if(filterDate){
      allDataTemp = allDataTemp.filter((item) => moment(item.pickup_date).isBetween(moment(filterDate[0]),moment(filterDate[1]), 'days', '[]'));
    }

    setData(allDataTemp);    
  },[allData,filterText,filterDate])

  const makeReceivedAll = (item)=>{
    let itemIds = item?.products?.map(sub=>{
      if(sub.is_wh_received === 0){
        return sub.id;
      }
      return null;
    });
    itemIds = itemIds.filter(i=> i !== null);
    
    setLoading(true);

    let postData = {bookingPIds:itemIds };

    axios.post(process.env.REACT_APP_API_URL + 'v1/make-as-received',postData, {
      headers: {
        Authorization: `Bearer ${auth?.api_token}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){
        axios(process.env.REACT_APP_API_URL + 'v1/picked-item', {
          headers: {
            Authorization: `Bearer ${auth?.api_token}`,
          }
        }).then(response => {
          if(response.data.status === 'SUCCESS'){
            let allData = response.data.data;
            setLoading(false);
            setAllData(allData);
          }else{
            setLoading(false);
          }
        }).catch(error=>{
          setLoading(false);
        });
      }else{
        setLoading(false);
      }
    }).catch(error => {
      setLoading(false);
    });
  }

  const makeReceived = (item)=>{
    setLoading(true);

    let postData = {bookingPIds:[item?.id] };

    axios.post(process.env.REACT_APP_API_URL + 'v1/make-as-received',postData, {
      headers: {
        Authorization: `Bearer ${auth?.api_token}`,
      }
    }).then(response => {
      if(response.data.status === 'SUCCESS'){

        axios(process.env.REACT_APP_API_URL + 'v1/picked-item', {
          headers: {
            Authorization: `Bearer ${auth?.api_token}`,
          }
        }).then(response => {
          if(response.data.status === 'SUCCESS'){
            let allData = response.data.data;
            setLoading(false);
            setAllData(allData);
          }else{
            setLoading(false);
          }
        }).catch(error=>{
          setLoading(false);
        });
      }else{
        setLoading(false);
      }
    }).catch(error => {
      setLoading(false);
    });
  }

  return (<>
    {loading && <Loader />}
    {contentLoading && <div className="LoaderCard"><div className="Loader TableLoader"><TableLoader /></div></div>}
    {!contentLoading && <div className={`${styles.MainDiv}`}>
        <div className={`${styles.PageTitleWrap}`}>
          <p className={`${styles.PageTitle}`}>View Picked Items</p>
          <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
            <Typography color="text.primary">Bookings</Typography>
            <Typography color="text.primary">Picked Items</Typography>
          </Breadcrumbs>
        </div>
        
        <div className={`${styles.MainCard}`}>
        <div className='TableFilterHead'>
          <div className="DataTableSearch">
            <TextField id="search" type="text" placeholder="Search" aria-label="Search Input" value={filterText} onChange={(e)=>setFilterText(e.target.value)} autoComplete='off' />
            {filterText && <Button type="button"  className="CloseBU" onClick={(e)=>setFilterText('')}><X /></Button>}
          </div>
          <div style={{width:'320px', marginLeft:'16px'}}>
              <DateRangePicker
                showOneCalendar
                format="dd/MM/yyyy"
                placeholder="Search by date"
                ranges={[]}
                onChange={(e)=>setFilterDate(e)}
              /> 
            </div>
        </div>
        
        <div className='TableContent'>
          <DataTable
            className='DataTable'
            columns={columns}
            data={data}
            pagination
            customStyles={customDataTableStyles}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
          />
        </div>
      </div>
    </div>}
  </>);
}

export default withAdminAuth(PickedUpItems);